import {
  Notifications,
} from '@material-ui/icons';

import paths from 'paths';

export default {
  id: 'notifications-menu',
  type: 'group',
  children: [
    {
      id: 'notifications',
      title: 'Notifications',
      type: 'item',
      url: `${paths.notifications.reverse()}?isReaded=no`,
      icon: Notifications,
      breadcrumbs: false,
    },
  ],
};
