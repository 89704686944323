import React from 'react';
import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import { Provider } from 'react-redux';

// load mock apis
import './_mockApis';

// project imports
import * as serviceWorker from './serviceWorker';
import App from './App';
import config from './config';
import reducer from './store/reducer';

// style + assets
import './assets/scss/style.scss';

// -----------------------|| REACT DOM RENDER  ||-----------------------//

const store = createStore(reducer);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js');
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
setTimeout(() => {
  const app = document.getElementById('root');
  if (!app || app.innerHTML.trim() === '') {
    window.location.reload();
  }
}, 3000);
