import { useMutation, useLazyQuery } from '@apollo/client';
import { ALL_NOTIFICATIONS } from 'queries/notifications/queries';
import { READ_NOTIFICATION } from 'queries/notifications/mutations';

const useNotificationsCRUD = () => {
  const [getNotifications, {
    loading,
    called,
    data: { paginatedNotifications: allNotifications = [] } = {},
  }] = useLazyQuery(ALL_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
  });

  const [readNotification] = useMutation(READ_NOTIFICATION, {
    refetchQueries: [{ query: ALL_NOTIFICATIONS }], awaitRefetchQueries: true,
  });

  const handleReaded = notificationUuid => readNotification({
    variables: {
      notificationUuid,
    },
  });

  return {
    loading,
    allNotifications,
    called,
    handleReaded,
    getNotifications,
  };
};

export default useNotificationsCRUD;
