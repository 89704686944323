import { gql } from '@apollo/client/core';

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($email: String!, $password: String!) {
    resetPassword(email: $email, password: $password) {
      status
      message
    }
  }
`;

export const USER_LOGGED_IN_MUTATION = gql`
  mutation userLoggedIn($description: String!) {
    userLoggedIn(description: $description) {
      status
      message
    }
  }
`;

export const USER_LOGGED_OUT_MUTATION = gql`
  mutation userLoggedOut($firebaseUid: String!, $description: String!) {
    userLoggedOut(firebaseUid: $firebaseUid, description: $description) {
      status
      message
    }
  }
`;

export const USER_TOKEN_MUTATION = gql`
  mutation saveToken($uuid: String!, $token: String!) {
    saveToken(uuid: $uuid, token: $token) {
      status
      message
    }
  }
`;

