import {
  // Assessment as AssessmentIcon,
  // Dashboard as DashboardIcon,
  EmojiPeople as EmojiPeopleIcon,
  Refresh as RefreshIcon,
  SupervisorAccount as SupervisorAccountIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  School,
} from '@material-ui/icons';
// third-party

import paths from 'paths';

// constant

// -----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

// eslint-disable-next-line import/prefer-default-export
export default {
  id: 'menu-other',
  type: 'group',
  children: [
    /* {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: paths.dashboard.reverse(),
      icon: DashboardIcon,
      breadcrumbs: false,
    }, */
    {
      id: 'rotations',
      title: 'Rotations',
      type: 'item',
      url: paths.rotations.reverse(),
      icon: RefreshIcon,
      breadcrumbs: false,
    },
    {
      id: 'students',
      title: 'Students',
      type: 'item',
      url: paths.students.reverse(),
      icon: SupervisorAccountIcon,
      breadcrumbs: false,
    },
    {
      id: 'preceptors',
      title: 'Preceptors',
      type: 'item',
      url: paths.preceptors.reverse(),
      icon: SupervisedUserCircleIcon,
      breadcrumbs: false,
    },
    {
      id: 'requests',
      title: 'Search Requests',
      type: 'item',
      url: paths.requests.reverse(),
      icon: EmojiPeopleIcon,
      breadcrumbs: false,
    },
    {
      id: 'schools',
      title: 'Schools',
      type: 'item',
      url: paths.school.reverse(),
      icon: School,
      breadcrumbs: false,
    },
    /* {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      url: paths.reports.reverse(),
      icon: AssessmentIcon,
      breadcrumbs: false,
    }, */
  ],
};
