
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import paths from 'paths';

export default {
  id: 'reminders-menu',
  type: 'group',
  children: [
    {
      id: 'reminders',
      title: 'Reminders',
      type: 'item',
      url: `${paths.reminders.reverse()}?isCompleted=no&order=datetime`,
      icon: CalendarMonthIcon,
      breadcrumbs: false,
    },
  ],
};
