import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, ButtonBase } from '@material-ui/core';

// project imports
import { IconMenu2, IconX } from '@tabler/icons';
import LogoSection from '../LogoSection';
// import SearchSection from './SearchSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets

// style constant
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
    '&:hover': {
      background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
      color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light,
    },
  },
  boxContainer: {
    width: '228px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
}));

// -----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ handleLeftDrawerToggle, leftDrawerOpened }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box
          component="span"
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
            flexGrow: 1,
          }}
        >
          <LogoSection />
        </Box>
        <ButtonBase
          sx={{
            borderRadius: '12px',
            overflow: 'hidden',
            minWidth: '34px',
            minHeight: '34px',
            maxWidth: '34px',
            maxHeight: '34px',
          }}
        >
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            {leftDrawerOpened ? <IconX stroke={1.5} size="1.3rem" /> : <IconMenu2 stroke={1.5} size="1.3rem" />}
          </Avatar>
        </ButtonBase>
      </div>

      {/* header search */}
      {/* <SearchSection theme="light" /> */}
      <div className={classes.grow} />
      <div className={classes.grow} />

      {/* notification & profile */}
      <NotificationSection />
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{
        display: {
          xs: 'block',
          sm: 'none',
        },
      }}
      >
        <MobileSection />
      </Box>
    </React.Fragment>
  );
};

Header.defaultProps = {
  handleLeftDrawerToggle: () => {},
  leftDrawerOpened: false,
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  leftDrawerOpened: PropTypes.bool,
};

export default Header;
