import { gql } from '@apollo/client/core';

// eslint-disable-next-line import/prefer-default-export
export const ALL_NOTIFICATIONS = gql`
  query paginatedNotifications($first: Int, $skip: Int, $isReaded: Boolean, $dateRange: [String], $search: String) {
    paginatedNotifications(first: $first, skip: $skip, isReaded: $isReaded, dateRange: $dateRange, search: $search) {
      totalCount
      totalCountInDb
      totalUnreaded
      results{
        uuid
        title
        body
        isReaded
        actionUrl
        createdAt
      }
    }
  }
`;
