import { gql } from '@apollo/client';

export const NOTIFICATIONS_MUTATION = gql`
  mutation notifications($input: NotificationMutationInput!){
    notification(input: $input) {
      notification {
        title
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const READ_NOTIFICATION = gql`
  mutation readNotification($notificationUuid: String!) {
    readNotification(notificationUuid: $notificationUuid) {
      notification {
        uuid
        title
        body
        isReaded
      }
    }
  }
`;
